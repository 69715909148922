<template>
  <default-layout>
    <template #content>
      <h1 class="mt-16 text-2xl text-center uppercase">Login</h1>
      <article class="p-5 mx-auto">
        <header class="text-center mt-8">
          <p>
            Please enter your account
            <br />
            email address and password.
          </p>
        </header>
        <transition name="fade">
          <p v-if="error" class="bg-red-100 p-5 my-5 border border-red-200 rounded text-red-500">{{ error }}</p>
        </transition>
        <form @submit.prevent="validateBeforeSubmit" class="mt-12 space-y-8">
          <div>
            <label for="email" class="font-bold text-gray-700 hidden">Email</label>
            <form-input
              v-model="email"
              v-validate="'required|email'"
              type="email"
              placeholder="Email"
              name="email"
              class="w-full text-center bg-white px-4 py-2 border-solid border-b-2 border-primary focus:outline-none"
            />
            <span v-show="errors.has('email')" class="text-xs text-red-500">
              {{ errors.first("email") }}
            </span>
          </div>
          <div>
            <label class="label hidden">Password</label>
            <form-input
              v-model="password"
              v-validate="'required'"
              type="password"
              placeholder="Password"
              name="password"
              class="w-full text-center bg-white px-4 py-2 border-solid border-b-2 border-primary focus:outline-none"
            />
            <span v-show="errors.has('password')" class="text-xs text-red-500">{{ errors.first("password") }}</span>
          </div>
          <div class="form-actions">
            <button class="form-button form-button--full mb-4 uppercase">login</button>
            <nav-button to="/" outline>Back</nav-button>
            <router-link to="/reset" class="flex justify-center hover:underline">Forgot password</router-link>
          </div>
        </form>
      </article>
    </template>
  </default-layout>
</template>

<script>
import { FormInput, NavButton } from "@/components";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { mapGetters } from "vuex";

export default {
  name: "signin",
  components: { FormInput, NavButton, DefaultLayout },
  data: () => ({
    email: "",
    password: ""
  }),
  computed: {
    ...mapGetters({ currentUser: "auth/user", error: "auth/error" })
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.trySignIn();
          return;
        }
        console.log("The form is not submitted. Correct the errors");
      });
    },
    trySignIn() {
      this.$store.dispatch("auth/signIn", { email: this.email, password: this.password }).then((response) => {
        if (response) {
          this.$store
            .dispatch("userData/isPasswordOutdated", response.user)
            .then((outdated) => (outdated ? this.$router.push("/update-password") : this.$router.push("/")));
        }
      });
    }
  },
  beforeDestroy() {
    this.$store.commit("auth/SET_USER_ERROR", null);
  }
};
</script>
